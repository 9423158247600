<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron definiciones de anomalías"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'three-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :page-loaded="pageLoaded"
      @click-item="clickListItem"
    >
      <template
        #listItem="slotProps"
      >
        <anomalia-material-add-list-item
          :item="slotProps.item"
          :index="slotProps.index"
        />
      </template>
    </b10-list>
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './AnomaliaMaterialAddListData'
import AnomaliaMaterialAddListItem from './components/AnomaliaMaterialAddListItem'
import { get } from 'vuex-pathify'


const pageStoreName = 'pagesOfflineAnomaliaMaterialAddList'

export default {
  components: {
    AnomaliaMaterialAddListItem
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      showingDialogs: {
        legends: false,
      },
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Buscar anomalía para añadir'
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const dataset = await Data.selectTanomaliaChecklist(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idparte_trabajo_matsist,
        )
        await this.loadItemsBase(dataset, [])
      } finally {
        this.loadingData = false
      }
    },
    clickListItem (item) {
      this.$appRouter.push({
        name: 'offline__anomalia-add',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idchecklist_ot: item.data.grupo_checklist_ot.idchecklist_ot,
          idgrupo_checklist_ot: item.data.pregunta_checklist_ot.idgrupo_checklist_ot,
          idpregunta_checklist_ot: item.data.pregunta_checklist_ot.idpregunta_checklist_ot,
        },
        query: {
          idtanomalia_checklist: item.data.tanomalia_checklist.idtanomalia_checklist,
          idgrado_anomalia: item.data.tanomalia_checklist.idgrado_anomalia,
        }
      })
    },
  },
}
</script>
