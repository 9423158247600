import { likeRegExp } from '@/offline/database'

export default {
  async selectTanomaliaChecklist (Vue, filter, search, sorter, page, idparteTrabajoMatsist) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.tanomalia_checklist.descripcion.match(reQ),
          tables.pregunta_checklist_ot.descripcion.match(reQ),
          tables.pregunta_checklist_ot.alias.match(reQ),
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.parte_trabajo_matsist.idparte_trabajo_matsist.eq(idparteTrabajoMatsist),
        tables.tanomalia_checklist.estado.gt(0),
        tables.pregunta_checklist_ot.estado.gt(0),
        ...whereSearch,
      )
    )
    return Vue.$offline.db
      .select()
      .from(tables.tanomalia_checklist)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.tanomalia_checklist.idpregunta_checklist.eq(tables.pregunta_checklist_ot.idpregunta_checklist)
      )
      .innerJoin(
        tables.grado_anomalia,
        tables.tanomalia_checklist.idgrado_anomalia.eq(tables.grado_anomalia.idgrado_anomalia)
      )
      .innerJoin(
        tables.parte_trabajo_matsist,
        tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(tables.parte_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .where(...where)
      .orderBy(tables.pregunta_checklist_ot.alias)
      .exec()
  },
}
